import React, {useState} from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';
import {toast} from 'react-toastify';
import 'babel-polyfill';

import Container from "react-bootstrap/Container";
import {InputGroup} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "@material-ui/core/Button";


const App = () => {
    const [email, setEmail] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [success, setIsSuccess] = useState(false);


    const submit = async () => {
        setIsSubmitting(true);
        try {
            const response = await axios.get('/access-tokens/' + encodeURIComponent(email));
            setIsSuccess(true);

            console.log(response);
        } catch (e) {
            console.error(e);
            toast.error(e.response.data.message);
            console.log(JSON.stringify(e));
        } finally {
            setIsSubmitting(false);
        }
    };
    return (
        <Container>

            {!success && <h2 className={"text-center"}>Access Cubeit</h2>}
            {!success ?
                <div className={"d-flex justify-content-center my-5 "}>
                    <div className={"border p-4 rounded shadow p-3 mb-5 bg-white rounded"} style={{maxWidth: '30em'}}>

                        {/*<p style={{color: "#777"}}>
                            In order to gain access to your information you must supply the email address used when you were registered by a technician as a
                            customer.
                        </p>*/}


                        {/*<label>Enter your email address</label>*/}
                        <InputGroup>
                            <Form.Control
                                type={"email"}
                                placeholder={"Email Address"}
                                value={email || ''}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <InputGroup.Append>
                                <Button
                                    style={{background: '#409bf2', color: 'white'}}
                                    variant="contained"
                                    onClick={() => submit()}
                                    disabled={!email || isSubmitting}
                                >
                                    {!isSubmitting ?
                                        'Send'
                                        :
                                        <><i className={"fas fa-spinner fa-spin"}></i> Working...</>
                                    }

                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                </div>
                :
                <div className={"d-flex justify-content-center my-5"}>
                    <div className={"border p-4 rounded"} style={{maxWidth: '30em'}}>
                        <h2 className={"text-center"}><i className={"fas fa-check-circle fa-2x text-success"}></i></h2>
                        <h3 className={"text-center"}>Success!</h3>

                        <p className={"text-center"}>You will receive an email at <b>{email}</b> containing one or more
                            links you can use to access
                            your data.</p>
                    </div>
                </div>
            }
            {!success && <a href='/login' className={'d-flex justify-content-center my-5'}><Button
                variant={"contained"}
                style={{background: '#409bf2', color: 'white'}}
            >I'm a technician</Button></a>}
            {!success && <p className={'d-flex justify-content-center my-5'}>
                <a href={'/terms-of-use/'}><p className={"pr-2"}>Terms of Use</p></a>
                <a href={'/help/'}><p className={"pl-2 pr-2 border-left border-right"}>Help</p></a>
                <a href={'/privacy-policy/'}><p className={"pl-2"}>Privacy Policy</p></a>
            </p>}
        </Container>
    )
};

export default App;

ReactDOM.render(<App/>, document.getElementById('app'));